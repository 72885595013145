import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'

const Colour = () => {
  return (
    <div id="colors" className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100 background-colour-beige-2">
          <div className="col-md-6 order-md-2 background-image background-color"></div>
          <div className="col-md-6 order-md-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6 order-md-2">
            <div className="spacer-background-image"></div>
          </section>
          <section className="col-md-6 order-md-1 p-5">
            <h2>Colour</h2>
            <h4>
              Healthy hair is of the highest importance, what you can expect
              when treating yourself to any colour service is:
            </h4>
            <p>
              Colour consult and Hair analysis - Your hair's integrity is
              important to achieve your hair goals, we will make sure we choose
              the colour that right for you!
            </p>
            <p>
              Colour Prep - To improve colour penetration & retention.
              Reconstructs & evens prorsity. (Quinoa & Vegetable proteins,
              Aminodew, Chia, Sunflower & Swiss grape Seed Extract)
            </p>
            <p>
              Hybrid Colour System & Developer - For beautiful, uniform colour
              results to extend your colour & vibrancy. Improve condition,
              softness and shine. Stabilised and pH controlled. (Argan & Jojoba
              Oil, Sunflower Seed Extract and Quinoa Protein) & (Olive Oil &
              Meadowfoam Seed Oil)
            </p>
            <p>
              Bond Multiplier - Cross-links & repairs broken bonds. Soothes,
              calms & protects the scalp. (Wild Daisy & Chamomile Extract,
              Jojoba, Argan & Colza Plant Oils and Quinoa Proteins)
            </p>
            <p>
              Relax- Indulge in your favourite drink and a treat with tunes and
              great conversation.
            </p>
            <p>
              Basin - Once it’s time to rinse your hair, recline back in our
              beautiful massage chair with dimmed lights to have the ultimate
              basin experience.
            </p>
            <p>
              Shampoo - Enjoy my hand selected shampoo specifically designed for
              your hair type.
            </p>
            <p>
              Colour Stability Creme - Intelligent repair & conditioning
              treatment. Stabilises & Secures colour molecules. Prolongs colour
              life, amplifies shine. (Colza Pland. Sunflower & Grape Seed
              Extract, Quinoa, Baobab & Wheat Protein, Jojoba Oil, Panthenol &
              Aminodew)
            </p>
            <p>
              Massage- Indulge in my signature head massage to relive stress and
              maximise relaxation.
            </p>
            <p>
              Finish - Choose your desired finishing service from elegant style
              cut and blowdry or a bouncy curls.
            </p>
            <a
              href={BOOKING_LINK}
              rel="noreferrer"
              target="_blank"
              className="links"
            >
              Book A Colour
            </a>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Colour
